import { IonLoading, IonSkeletonText, IonThumbnail, isPlatform, IonPage } from '@ionic/react'
import { PageWrapperComponent } from 'modules/common'
import MovieCarousel from 'modules/common/components/MovieCarousel/MovieCarousel'
import React, { useState, useMemo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { TRACK_EVENT } from "analytics/analyticsHelper";
import MovieCardShort from 'modules/common/components/MovieCard/Short/MovieCardShort'
import MovieCardNormal from 'modules/common/components/MovieCard/Normal/MovieCardNormal'
import MovieCardLongWeb from 'modules/common/components/MovieCard/Long/MovieCardLongWeb/MovieCardLongWeb'
import MovieCardLongMobile from 'modules/common/components/MovieCard/Long/MovieCardLongMobile/MovieCardLongMobile'
import { HeaderComponent } from "modules/common";
import "./GenreList.scss";

const GenreList = () => {

    const [skeleton, setSkeleton] = useState(null);
    const isMobile = isPlatform("mobile");
    const location = useLocation().state
    const movieList = location.movies
    const name = location.name
    console.log(movieList, name)
    const [isLoading, setIsLoading] = useState(true);
    const [showSkeleton, setShowSkeleton] = useState({
        short: true,
        long: true,
        normal: true,
    });

    const normal = useMemo(() => {
        return isMobile
            ? movieList?.map((movie, i) =>
                skeleton?.short ? (
                    <IonThumbnail key={`movie-short-skeleton-${i}`} className="skeleton-card-short">
                        <IonSkeletonText animated />
                    </IonThumbnail>
                ) : (
                    <MovieCardShort key={`movie-carousel-movie-short-${i}${movie?.id}`} movie={movie} screen={screen} />
                )
            )
            : movieList?.map((movie, i) =>
                skeleton?.normal ? (
                    <IonThumbnail className="skeleton-card-normal" key={i}>
                        <IonSkeletonText animated />
                    </IonThumbnail>
                ) : (
                    <MovieCardNormal key={`movie-carousel-movie-short-${i}${movie?.id}`} movie={movie} screen={screen} />
                    // <MovieCardOne key={`movie-carousel-movie-${i}${movie?.id}`} movie={movie} screen={screen} />
                )
            );
    }, [movieList, skeleton, name]);

    const long = useMemo(() => {
        return isMobile
            ? movieList?.map((movie, i) =>
                skeleton?.long ? (
                    <IonThumbnail key={`mobile-carousel-skeleton-${i}`} className="skeleton-card-long-mobile">
                        <IonSkeletonText animated />
                    </IonThumbnail>
                ) : (
                    <MovieCardLongMobile key={`mobile-carousel-movie-${i}`} collection={movie} screen={screen} />
                )
            )
            : movieList?.map((movie, i) =>
                skeleton?.long ? (
                    <IonThumbnail key={`web-carousel-skeleton-${i}`} className="skeleton-card-long">
                        <IonSkeletonText animated />
                    </IonThumbnail>
                ) : (
                    <MovieCardLongWeb key={`web-carousel-movie-${i}`} movie={movie} screen={screen} />
                )
            );
    }, [movieList, skeleton]);

    const getTitle = () => {
        return name
    }

    return (
            <PageWrapperComponent pageClass={'genrelist'} pageTitle={getTitle()} hideSearch={false} hideLogo={true} hideMenuButton={true} hideBack={false} >

                {/* {movieList?.map((data, i) => {
                console.log(data.id)
                Object.values(data).length? ( */}
                <div className="container">
                    <div className="movie-card d-flex flex-wrap">
                        {normal}
                    </div>
                </div>
            </PageWrapperComponent>
    )
}

export default GenreList