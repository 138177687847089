import React, { useEffect, useState } from "react";
import "./StaticPagesMobile.scss";
import { useRouteMatch } from "react-router";
import {
    IonButton,
    IonPage,
} from "@ionic/react";
import { BUSINESS_CONFIG,ABOUT_US, LOGO} from "../../../../clientConfig";
import { setPageTitle } from "helpers/seoHelper";
import { PageWrapperComponent } from "modules/common";
import { HeaderComponent } from "modules/common";


const AboutUs = () => {
    useEffect(() => {
        setPageTitle("About Us");
    }, []);
 
 
    const getTitle = () => {
        return "About Us"
    }
    return (
        <>
                <PageWrapperComponent hideHeader={false} pageTitle={getTitle()} hideMenuButton={true} hideLogo={true} hideBack={false} hideSearch={true}>
                    <div className="aboutUs-wrapper">
                    <div className="aboutUs-img">
                    <img
                    className="logoimg"
                    src= {LOGO}
                    alt="logo"
                />
                    </div>
                    <div className="body-text">{ABOUT_US}</div>
                    </div>
                </PageWrapperComponent>
        </>
    )
}
export default AboutUs;

