/* no-nested-ternary */
import React, { useEffect, useState } from "react";
import "./MoviePayment.scss";
import {
  IonButton,
  isPlatform,
} from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { APP_THEME, PAYMENT } from "../../../../clientConfig";

const MoviePayment = ({
  closePaymentPopUp,
  tier,
  setTier,
  setIsSubscription,
  hidePayPerView,
  setPaymentGateway,
  showPaymentGateway,
  userPlans,
}) => {
  const getTiers = () => [
    {
      title: "Watch now in HD",
      subTitle:
        "Best suited for computer, tv and mobile screen with 4g internet connection",
      value: "tier1",
    },
    {
      title: " Watch now in 4k",
      subTitle:
        "Best suited for computer, tvs with minimum 10 Mbs brodband connect",
      value: "tier2",
    },
  ];

  const [tiers, setTiers] = useState(getTiers());

  const onSubscriptionClick = (value) => {
    setTier(value);
    setIsSubscription(true);
    // closePaymentPopUp(false);
  };

  const onPayPerViewClick = (value) => {
    setTier(value);
    setIsSubscription(false);
    // closePaymentPopUp(false);
  };

  const gateways = () => userPlans?.find((x) => x.period === tier)?.gateways;

  return (
    <>
      {showPaymentGateway && tier ? (
        <div
          className="movie-payment"
          // onClick={handleClick}
          id="movie-payment-shadow"
        >
          <div className="page-main-img">
            <img
              src="https://jugnu.ams3.cdn.digitaloceanspaces.com/001staticimageassets/Frame%20125.png"
              alt="main"
            />
          </div>
          <div className="payment-content">
            <div className="payment-heading">
              Please select your payment gateway:
            </div>
            <div className="movie-payment-methods">
              <div className="action-wrap">
                {gateways()?.map((gateway) => {
                  if (
                    (gateway?.name === "playstore_billing" ) &&
                    ( !Capacitor.isNativePlatform() || isPlatform("ios") )
                  ) {
                    return null;
                  }
                  if (
                    gateway?.name === "appstore_billing" &&
                    ( !Capacitor.isNativePlatform() || isPlatform("android") )
                  ) {
                    return null;
                  }
                  if(Capacitor.isNativePlatform() && isPlatform("ios") && gateway?.name === "razorpay") {
                    return null;
                  }
                  return (
                    <button
                      className="payment-btn-active"
                      onClick={() => {
                        setPaymentGateway(gateway?.name);
                      }}
                    >
                      Pay with {gateway?.name?.replace("_"," ")?.toUpperCase()}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : PAYMENT.IS_PAY_PER_VIEW ? (
        <div
          className="movie-payment"
          // onClick={handleClick}
          id="movie-payment-shadow"
        >
          <div className="movie-payment-methods">
            {tiers?.map((t, i) => (
              <div className="action-wrap" key={i}>
                <div className="payment-heading">{t?.title}</div>
                <div className="payment-desc">{t?.subTitle}</div>

                <button
                  key={i}
                  className="payment-btn-active"
                  onClick={() => {
                    onPayPerViewClick(t?.value);
                  }}
                >
                  {t?.title}
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="subscription-wrap">
          <div className="page-main-img">
            <img
              src="https://nine-rasa-all.ams3.digitaloceanspaces.com/Assets/Frame%20124.png"
              alt="main"
            />
          </div>
          <div className="content-section">
            <div className="subscription-heading">Choose your plan.</div>
            <div className="subscription-desc">
              {/* <p>
                ✔️ No Commitment cancel any time.
              </p> */}
              <div className="subscription-desc-row">
                        <img className="icon" src="https://jugnu.ams3.cdn.digitaloceanspaces.com/001staticimageassets/Icons/Check-icon/check-icon-1.svg" alt="" />
                        <div className="subtype">No Commitment cancel any time.</div>
              </div>
              <div className="subscription-desc-row">
                        <img className="icon" src="https://jugnu.ams3.cdn.digitaloceanspaces.com/001staticimageassets/Icons/Check-icon/check-icon-1.svg" alt="" />
                        <div className="subtype">Everything on JugnuPlay at one low price.</div>
              </div>
              <div className="subscription-desc-row">
                        <img className="icon" src="https://jugnu.ams3.cdn.digitaloceanspaces.com/001staticimageassets/Icons/Check-icon/check-icon-1.svg" alt="" />
                        <div className="subtype">No ads and no extra fees. Ever.</div>
              </div>
              <div className="subscription-desc-row">
                        <img className="icon" src="https://jugnu.ams3.cdn.digitaloceanspaces.com/001staticimageassets/Icons/Check-icon/check-icon-1.svg" alt="" />
                        <div className="subtype">Consume content at HD Resolution.</div>
              </div>
              <div className="subscription-desc-row">
                        <img className="icon" src="https://jugnu.ams3.cdn.digitaloceanspaces.com/001staticimageassets/Icons/Check-icon/check-icon-1.svg" alt="" />
                        <div className="subtype">Access upto 4 devices.</div>
              </div>
            </div>
            <div className="plans-container">
              <div className="plan-heading">Plans</div>
              <div className="plan-box-wrap">
                {userPlans?.map((plan) => (
                  <div className="plan-box">
                    <div className="pricing">
                      {plan?.name?.toUpperCase()}:{" "}
                      <p className="pricing-amount">
                      {plan?.currency || "Rs"}{" "} {plan?.display_amount}
                      </p>
                    </div>
                    <IonButton
                      className="btnprimary"
                      color="secondary"
                      // shape="round"
                      size="default"
                      onClick={() => onSubscriptionClick(plan?.period)}
                    >
                      Buy Now
                    </IonButton>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="disclaimer">
              Please 
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default MoviePayment;
