import React, { useEffect, useState } from "react";
import "./Settings.scss";
import { FormattedMessage } from "react-intl";
import { useRouteMatch } from "react-router";
import {
  IonToggle,
  IonButton,
  IonPage,
} from "@ionic/react";
import { BUSINESS_CONFIG } from "../../../../clientConfig";
import { setPageTitle } from "helpers/seoHelper";
import { PageWrapperComponent } from "modules/common";
import { HeaderComponent } from "modules/common";
import { chevronForward } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { ROUTES } from "helpers/routeHelper";
import { Link } from "react-router-dom";

const Settings = ({ handleSelect, locale }) => {
  const [lang] = useState([
    { key: "en", val: "English" },
    { key: "hn", val: "हिंदी" },
    { key: "mr", val: "मराठी" },
  ]);
  useEffect(() => {
    setPageTitle("SETTING");
  }, []);

  const { path } = useRouteMatch();

  const getTitle = () => {
    if (path.includes('settings')) {
      return "Settings"
    }
  }
  return (
    <>
        <PageWrapperComponent hideHeader={false} pageTitle={getTitle()} hideMenuButton={true} hideLogo={true} hideBack={false} hideSearch={true}>
          <div className="settingsContainer container">
            <div className="settings">
              {/* <h3>App Settings</h3> */}

              <div className="infoBlock">
                {/* <div className="InfoBlockSubTitle">Notification & Language</div> */}
                {/* <div className="InfoBlockSubTitle">App Settings</div> */}
                <div className="InfoBlockSubTitle">Notification & Language</div>
                <div className="Infoblockbox">
                  <div className="infoBlockRow">
                    <div className="infoType">
                      <FormattedMessage id="setting.Language" />
                    </div>
                    <select
                      className="infoValue"
                      onChange={handleSelect}
                      defaultValue={locale}
                    >
                      {lang.map((l) => (
                        <option value={l.key} key={l.key}>
                          {l.val}
                        </option>
                      ))}
                    </select>
                    {/* <div className="infoValue">English</div> */}
                  </div>
                  <div className="infoBlockRow">
                    <div className="infoType">
                      <FormattedMessage id="setting.Notifications" />
                    </div>
                    <div className="infoValue">
                      <IonToggle checked={true} />
                    </div>
                  </div>
                </div>
                {/* <div className="infoBlockRow">
                    <div className="infoType">
                      <FormattedMessage id="setting.Notifications" />
                    </div>
                    <div className="infoValue">
                      <IonToggle checked={true} />
                    </div>
                  </div> */}
                {/* <div className="InfoBlockSubTitle">Other Settings</div> */}
                {/* <div className="InfoBlockSubTitle">App Info</div> */}
                <div className="InfoBlockSubTitle">Other Settings</div>
                {/* <div className="Infoblockbox"> */}
                  {/* <div className="infoBlockRow">
                  <div className="infoType">
                    <FormattedMessage id="setting.Contactus" />:
                  </div>
                  <div className="infoValue">
                    <a href="mailto:backstage@Ott.com?subject=Contact Us">
                      backstage@Ott.com
                    </a>
                  </div>
                </div> */}
                  {/* <div className="infoBlockRow">
                  <div className="infoType">
                    <FormattedMessage id="setting.Privacypolicy" />
                  </div>
                  <div className="infoValue">
                    {/* <a target="_blank" href={BUSINESS_CONFIG.PrivacyPolicy}>
                        Download PDF
                      </a> */}
                  {/* <IonButton
                      target="_blank"
                      href={BUSINESS_CONFIG.PrivacyPolicy}
                      size="small"
                      color="secondary"
                    >
                      Download PDF
                    </IonButton> */}
                  {/* </div> */}
                  {/* </div> */}
                  <div className="Infoblockbox">
                  <div className="infoBlockRow">
                    <div className="infoType">
                      <Link to={ROUTES.ABOUT_US} routerDirection="forward">
                        <span className="infoblock-row-name d-flex justify-content-between align-items-center">
                          <div className="infoType">About Us</div>
                          <IonIcon icon={chevronForward} color="secondary" />
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="infoBlockRow">
                    <div className="infoType">
                      <Link to={ROUTES.PRIVACY_POLICY} routerDirection="forward">
                        <span className="infoblock-row-name d-flex justify-content-between align-items-center">
                          <div className="infoType">Privacy Policy</div>
                          <IonIcon icon={chevronForward} color="secondary" />
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="infoBlockRow">
                    <div className="infoType">
                      <Link to={ROUTES.TERMS_OF_SERVICE} routerDirection="forward">
                        <span className="infoblock-row-name d-flex justify-content-between align-items-center">
                          <div className="infoType">Terms Of Service</div>
                          <IonIcon icon={chevronForward} color="secondary" />
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="infoBlockRow">
                    <div className="infoType">
                      <Link to={ROUTES.DISCLAIMER} routerDirection="forward">
                        <span className="infoblock-row-name d-flex justify-content-between align-items-center">
                          <div className="infoType">Disclaimer</div>
                          <IonIcon icon={chevronForward} color="secondary" />
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="infoBlockRow">
                    <div className="infoType">
                      <Link to={ROUTES.HELP_AND_SUPPORT} routerDirection="forward">
                        <span className="infoblock-row-name d-flex justify-content-between align-items-center">
                          <div className="infoType">Help & Support</div>
                          <IonIcon icon={chevronForward} color="secondary" />
                        </span>
                      </Link>
                    </div>
                  </div>
                  </div>

                {/* </div> */}
              </div>
            </div>
          </div>
        </PageWrapperComponent>
    </>
  );
};

export default Settings;
