import React, { useEffect, useState } from "react";
import "./StaticPagesMobile.scss";
import { useRouteMatch } from "react-router";
import {
    IonButton,
    IonPage,
} from "@ionic/react";
import { BUSINESS_CONFIG,DISCLAIMER } from "../../../../clientConfig";
import { setPageTitle } from "helpers/seoHelper";
import { PageWrapperComponent } from "modules/common";
import { HeaderComponent } from "modules/common";
 
const Disclaimer = () => {
    useEffect(() => {
        setPageTitle("Disclaimer");
    }, []);
 
 
    const getTitle = () => {
        return "Disclaimer"
    }
    return (
        <>
           
           <PageWrapperComponent hideHeader={false} pageTitle={getTitle()} hideMenuButton={true} hideLogo={true} hideBack={false} hideSearch={true}>
                    <div className="body-text">{DISCLAIMER}</div>
                </PageWrapperComponent>
        </>
    )
}
export default Disclaimer;
 

