import React, { useState } from "react";
import "./PlansPayment.scss";
import { useAnalytics } from "use-analytics";
import { IonButton, IonLoading } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ROUTES } from "helpers/routeHelper";
import {
  autoSignIn,
  resendOtp,
  verifyOtp
} from "actions/services/user.service";
import { saveUserDetails } from "store/slices/userSlice";
import { APP_THEME,
  SHOW_SIGNUP_WELCOME_MESSAGE,
  SHOW_SIGNUP_PAYMENT_PLANS } from "../../../../clientConfig";
import SuccessPayment from "../SuccessPayment/SuccessPayment";

const PlansPayment = ({
  userPlans,
  setShow,
  userVerified,
  isVerificationOnly,
  saveUserDetailsAction
}) => {
  const { track, page, identify } = useAnalytics();
  const history = useHistory();

  const [passcode, setPasscode] = useState(null);
  const [showPlans, setShowPlans] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [verifyMessage, setVerifyMessage] = useState("");

  const resend = () => {
    setShowLoading(true);
    resendOtp()
      .then(() => { })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const submitOtp = () => {
    setShowLoading(true);
    setVerifyMessage("");
    verifyOtp(passcode)
      .then(async res => {
        const data = res?.data?.data;
        if (res?.data?.success && data?.verified) {
          const user = await autoSignIn();
          setShowLoading(false);
          if (user) {
            saveUserDetailsAction(user?.data);
          }
          if (isVerificationOnly) {
            setShow(false);
          } else {
            setShowPlans(true);
          }
        } else {
          setVerifyMessage("Invalid OTP, Please try again");
        }
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  return (
    <div className={`ionPage`}>
      <IonLoading
        style={{ "z-index": "1000000" }}
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait a while..."}
        duration={5000}
      />
      <div className="main-body">
        {userVerified === false && !showPlans ? (
          <>
            <div className="planpayment-popup">
              <div className="main-heading-container">
                <div className="main-heading">Verification code</div>
                <div className="main-heading-sub">
                  Thank you for registration, Kindly verify your Contact
                  Information.
                </div>
                <div className="align-center">
                  <input
                    className="otp"
                    type="text"
                    placeholder="One time password"
                    id="passcode"
                    autoComplete="otp"
                    name="passcode"
                    value={passcode}
                    onChange={(e) => {
                      setPasscode(e.target.value);
                      setVerifyMessage("");
                    }}
                  />
                </div>
                <div className="resend-code d-flex justify-content-between">
                  <span className="text text-danger">{verifyMessage}</span>
                  <a onClick={() => resend()}>
                    Resend Code
                  </a>
                </div>
                <div className="action-row">
                  <IonButton
                    className="btnprimary"
                    color="secondary"
                    onClick={() => submitOtp()}
                  >
                    Submit
                  </IonButton>
                  <IonButton
                    className="btntransparent"
                    color="transparent"
                    onClick={() => {
                      if (isVerificationOnly) {
                        setShow(false);
                      } else {
                        setShowPlans(true);
                      }
                    }}
                  >
                    Skip
                  </IonButton>
                </div>
              </div>
            </div>
          </>
        ) : (
            <>
             
            <div className="planpayment-popup">
              {SHOW_SIGNUP_WELCOME_MESSAGE && (<div className="main-heading-container">
                <div className="main-heading">Welcome!</div>
                <div className="main-heading-sub">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sodales vehicula ex sed vestibulum. 
                  Etiam tincidunt metus gravida volutpat iaculis. Curabitur quis porta nulla. Nullam non egestas lacus. 
                </div>
                {/* <div className="align-center">
                  <IonButton
                    className="btnprimary"
                    color="secondary"
                    onClick={() => setShow(false)}
                  >
                    Continue Watching
                  </IonButton>
                </div> */}
                <div className="main-heading-sub">
                Sed finibus sapien et orci aliquam ullamcorper. Sed lobortis massa non odio consequat, at consectetur dui viverra. 
                Pellentesque mattis varius sem eget feugiat. Integer consequat eu sem porta hendrerit. 
                Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. 
                Quisque non tortor sed ligula bibendum hendrerit. Etiam vitae nunc aliquet, placerat ex id, venenatis urna.
                </div>
              </div>)}
              {SHOW_SIGNUP_PAYMENT_PLANS && (<div className="plans-container">
              <div className="plan-heading">Choose your plan</div>
              <div className="plan-heading-sub">
                  You can also avail our 30 day free trail on premium content by
                  selecting one of the plans below:
                </div>
              <div className="plan-box-wrap">
                {userPlans?.map((plan) => (
                  <div className="plan-box">
                    <div className="pricing">
                      {/* {plan?.name?.toUpperCase()}:{" "} */}
                      {plan?.name?.toUpperCase()}{" "}
                      <span>{plan?.currency || "Rs"}{" "} {plan?.display_amount}</span>
                    </div>
                    <IonButton
                      className="btnprimary"
                      color="secondary"
                      // shape="round"
                      size="default"
                      onClick={() => {
                        history.push(ROUTES.WATCH_SUBSCRIBE);
                        setShow(false);
                      }}
                    >
                      Buy Now
                    </IonButton>
                  </div>
                ))}
              </div>
            </div>)}
              {SHOW_SIGNUP_PAYMENT_PLANS && (<div className="disclaimer">
                Please note that a token sum of upto INR 10 only will be deducted
                from your account to validate your subscription details. You may
                choose to deactivate the same twenty four hours prior to your
                renewal date if you may so wish.
              </div>)}
              <div className="align-center">
                  <IonButton
                    className="btnprimary"
                    color="secondary"
                    onClick={() => setShow(false)}
                  >
                    Continue Watching
                  </IonButton>
                </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, app }) => ({
  userVerified: user?.verified,
  userPlans: user?.plans,
});

const mapDispatchToProps = {
  saveUserDetailsAction: saveUserDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(PlansPayment);
